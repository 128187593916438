/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

import * as application from './application';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
import * as polyfills from '@spinnwerk/polyfills';
polyfills.focusOptions();

// IE11 polyfills
// polyfills.dom();
// polyfills.fetch();
// polyfills.objectFit();
// polyfills.picturefill();
// polyfills.intersectionObserver();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* note: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */
import { init } from './_foundation';
init();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
import { fullHeightOnMobile } from './utils/full-height-on-mobile';
fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Security
import { initSecuringExternalAnchors } from './security/external-anchors';
initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// register service worker in production mode
application.serviceWorker();

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
import { loadFonts } from '../../components/base/fonts/fonts';
loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.handleTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: 0,
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

import { firstLastClasses } from './utils/first-last-classes';
// the items in the footer navigation and the items in the container needs to be checked separately
firstLastClasses('.footer__small__nav > *');
firstLastClasses('.footer__small__nav .menu__item');

// ////////////////////////////////////////////////////////////
// browser alerts
import { oldBrowserAlert } from '../../components/patterns/molecules/browser-alerts';
oldBrowserAlert();
// cookieInfoAlert();
// fix a11y flaws of Borlabs Cookie Manager
import { borlabsCookieA11yFixes } from '../../components/patterns/molecules/browser-alerts/borlabs-cookies';
borlabsCookieA11yFixes();

// ////////////////////////////////////////////////////////////
// Butterfly generator
import { initButterflyGenerator } from '../../components/patterns/vue-components/butterfly-generator';
initButterflyGenerator();

document.querySelectorAll('[data-click-track]').forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: event.currentTarget.dataset.clickTrack,
        });
    });
});
