import Vue from 'vue';

export function initButterflyGenerator() {
    const butterflyGenerator = document.querySelector('#butterfly-generator');

    if (butterflyGenerator) {
        window.addEventListener('message', ({ data, origin }) => {
            if (origin !== 'https://secure.sicherhelfen.org') {
                return;
            }

            const iFrame = document.querySelector('#newsletter');

            if (iFrame && isNaN(data) === false) {
                iFrame.height = data + 'px';
            }
        });

        new Vue({
            el: '#butterfly-generator',
            name: 'App',
            components: {
                'butterfly-generator': () => import(/* webpackMode: "lazy" */ './butterfly-generator.vue'),
            },
        });
    }
}
